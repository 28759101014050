  <nav>
    <ul>
      <li *ngFor="let nameAndUrl of namesAndUrls">
        <a id="nameAndUrl.get('name')"
         tabindex="0"
         (keyup.enter)="navToUrl(nameAndUrl.get('url'), nameAndUrl.get('partNum'))"
         (click)="navToUrl(nameAndUrl.get('url'), nameAndUrl.get('partNum'))">
           {{ nameAndUrl.get('name') }}
        </a>
      </li>
    </ul>
  </nav>
